import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { useNavigate } from "react-router-dom";
import { app_url } from "../../config";
import axios from "axios";
import { CiSearch } from "react-icons/ci";
import Loader from "../Loader";
import "../../css/existingCustomers.css";
import useResponsive from "../../customHooks/useResponsive";
import toast from "react-hot-toast";
import NetworkErr from "../NetworkErr";

const SendBulkExisting = ({
  openExisting,
  closeExisting,
  packageId,
  emptyEid,
  changeTabOnSendingEsim,
  existingPackageName,
  quantity,
  count,
  setCount,
}) => {
  const [search, setSearch] = useState("");
  const [totalSearch, setTotalSearch] = useState(0);
  const [sendTo, setSendTo] = useState();
  const [areYouSure, setAreYouSure] = useState(false);
  const agentId = JSON.parse(localStorage.getItem("etijwaal-agent"))?.id;
  const token = JSON.parse(localStorage.getItem("etijwaal-agent"))?.token;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [anomoly, setAnomaly] = useState("");
  const [networkErr, setNetworkErr] = useState(navigator.onLine);
  const navigate = useNavigate();
  const { width } = useResponsive();

  function searchValue(e) {
    const value = e.target.value;
    setSearch(value);
  }

  function getUserInput(e) {
    const value = e.target.value;
    setSendTo(value);
  }

  function sendEsim(e) {
    if (!sendTo) {
      toast.error("Please select a Contact to send eSim to...");
      return;
    }
    setAreYouSure(true);
  }

  // const increment = () => {
  //   if (count >= quantity) {
  //     return;
  //   } else {
  //     setCount((count += 1));
  //   }
  // };
  // const decrement = () => {
  //   if (count <= 1) {
  //     return;
  //   } else {
  //     setCount((count -= 1));
  //   }
  // };

  const increment = () => {
    if (count < quantity) {
      setCount((prevCount) => prevCount + 1);
    }
  };

  const decrement = () => {
    if (count > 1) {
      setCount((prevCount) => prevCount - 1);
    }
  };

  useEffect(() => {
    if (packageId) {
      setIsLoading(true);
      setSendTo("");
      axios
        .get(
          `${app_url}/api/customer/getAllCustomersByPartner?pId=${agentId}&numPerPage=10&search=${search}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const { result, totalCount } = response.data;
          setData(result);
          setIsLoading(false);
          setTotalCount(totalCount);
          setTotalSearch(result.length);
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            navigate("/");
          } else if (err.response?.status === 400) {
            toast.error(err.response?.data.message);
          } else {
            setAnomaly(err.message);
            setNetworkErr(navigator.onLine);
          }
          setIsLoading(false);
        });
    }
  }, [search, packageId]);

  useEffect(() => {
    setNetworkErr(navigator.onLine);
  });

  function sendToCustomer() {
    setIsLoading(true);
    axios
      .post(
        `${app_url}/api/customer/AssignBulkEsimToExistingCustomerPartner`,
        { cId: sendTo, quantity: count, packageId: packageId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message);
        setIsLoading(false);
        changeTabOnSendingEsim();
        setSendTo("");
        setAreYouSure(false);
        emptyEid();
        closeExisting();
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          navigate("/");
        } else {
          setNetworkErr(true);
          setAnomaly(err.message);
        }
        setIsLoading(false);
        toast.error(err.response?.data.message);
      });
  }

  return (
    <Modal
      modalId="style-3"
      open={openExisting}
      styles={{
        modal: {
          padding: 0,
          position: "relative",
          width: width < 840 ? "90%" : "100%",
          height: data.length < 5 ? "auto" : "600px",
          borderRadius: "4px 4px 4px",
        },
        closeIcon: {
          fill: "#000",
          marginRight: "0.5em",
          width: "100%",
          overflowY: "hidden",
        },
      }}
      showCloseIcon={false}
      onClose={() => {
        closeExisting();
        setSendTo("");
        setAreYouSure(false);
        setSearch("");
      }}
      center
    >
      {!networkErr ? (
        <NetworkErr data={anomoly} minHeight={"min-h-full"} />
      ) : (
        <>
          <section className={`${areYouSure ? "pointer-events-none" : ""}`}>
            <div className="flex flex-col sticky top-0 z-50 bg-white pb-4 shadow-lg sm:flex-row items-center justify-center sm:justify-between px-3 sm:ps-3 pt-4">
              <div className="flex items-center flex-col sm:flex-row justify-center sm:justify-normal text-base sm:text-2xl  gap-2">
                <img
                  src="/assets/esim.png"
                  className="sm:w-[50px] sm:h-[50px] w-[80px] h-[80px]"
                  alt="esims"
                />
                <div>
                  <p className="text-base">{existingPackageName}</p>
                  <h2 className="text-lg text-center sm:text-start sm:text-2xl text-gray-600 font-semibold">
                    Available Quantity: {quantity}
                  </h2>
                  <div className="flex gap-2 bg-red-500 text-white shadow px-2 mt-2 rounded items-center">
                    <h2>Assign</h2>
                    <div className="flex items-center gap-4">
                      <button
                        onClick={decrement}
                        className="text-xl font-bold cursor-pointer hover:scale-150 transition-all duration-500 rounded"
                      >
                        -
                      </button>

                      <div className="w-[109px] text-black h-[40px] flex items-center justify-center shadow bg-gray-50 font-bold">
                        {count}
                      </div>
                      <button
                        onClick={increment}
                        className="text-xl font-bold cursor-pointer hover:scale-150 transition-all duration-500 rounded"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <button
                onClick={sendEsim}
                className={`bg-primary absolute top-0 right-0 m-3 sm:static sm:m-0 text-xs mt-2 sm:mt-0 rounded-md sm:text-base p-2 ${
                  !sendTo ? "hidden" : "block"
                } text-white sm:rounded-xl`}
              >
                Send
              </button>
            </div>
            <article className="flex gap-2 px-2 w-full">
              {/* <div className="text-sm hidden flex-2 sm:flex items-center gap-4"><select onChange={entries} className="rounded-3xl max-w-[50px]: border py-3 bg-transparent px-2  xl:text-lg text-gray-500" name="" id=""><option hidden="" value="Select Month">Entries</option><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select></div> */}
              <div className="relative mb-3 mt-4 w-full">
                <input
                  onChange={searchValue}
                  value={search}
                  type="text"
                  placeholder="Search Contacts"
                  className="w-full py-3 rounded-3xl bg-transparent border px-12"
                />
                <CiSearch
                  className="absolute top-[15px] left-[18px]"
                  size={20}
                />
                <p className="text-xs absolute right-[31px] top-[18px] text-red-600">
                  {totalSearch}
                </p>
              </div>
            </article>
            <article className="bg-white mb-8">
              {isLoading ? (
                <div
                  className={`${
                    data.length < 5 ? "h-[100px]" : "h-[400px]"
                  }  w-full flex items-center justify-center`}
                >
                  <Loader />
                </div>
              ) : data.length === 0 ? (
                <div className="h-[300px] w-full flex items-center justify-center">
                  <p className="text-xl">No Customers Found!</p>
                </div>
              ) : (
                data.map((results) => (
                  <div
                    className="flex items-center justify-between border-b"
                    key={results.id}
                  >
                    <label
                      htmlFor={`user-${results.id}`}
                      className="flex flex-row w-full cursor-pointer items-center gap-2 sm:gap-5 px-2 py-2 bg-transparent "
                    >
                      <div>
                        <img
                          src="/assets/avatar-nosex.png"
                          alt=""
                          className="w-[20px] h-[20px] sm:w-[40px] sm:h-[40px] rounded-full"
                        />
                      </div>
                      <div className="text-xs sm:text-sm sm:text-left">
                        <h2>
                          {results.first_name} {results.last_name}
                        </h2>
                        <p className="text-gray-500 font-thin">
                          {results.email}
                        </p>
                        <p className="text-gray-500 font-thin">
                          {results.mobile}
                        </p>
                      </div>
                    </label>
                    <input
                      className="me-3"
                      type="radio"
                      name="selectedUser"
                      id={`user-${results.id}`}
                      value={results.id}
                      onChange={getUserInput}
                      // You can add an onChange handler if you need to handle selection changes
                    />
                  </div>
                ))
              )}
            </article>
          </section>

          <div
            className={`sticky ${
              areYouSure ? "flex" : "hidden"
            } bottom-0 bg-red-400 flex items-center justify-center w-full px-4 sm:px-0 py-3`}
          >
            <article>
              <p className="text-white fo">
                Are you sure you want to send eSim to the selected contact?
              </p>
              <div className="flex mt-4 items-center  justify-center gap-4">
                <button
                  onClick={sendToCustomer}
                  disabled={isLoading}
                  className="px-2 py-1 bg-green-600 text-white rounded-lg shadow-xl"
                >
                  {isLoading ? <Loader /> : "Yes"}
                </button>
                <button
                  onClick={() => {
                    setAreYouSure(false);
                  }}
                  disabled={isLoading}
                  className="px-2 py-1 bg-red-800 text-white rounded-lg shadow-xl"
                >
                  No
                </button>
              </div>
            </article>
          </div>
        </>
      )}
    </Modal>
  );
};

export default SendBulkExisting;
