import { CardElement } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { app_url } from "../../config";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const CheckoutForm = ({ amount, clsc, currency }) => {
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const elements = useElements();
  const navigate = useNavigate();
  const stripe = useStripe();
  const token = JSON.parse(localStorage.getItem("etijwaal-agent"))?.token;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    const cardElement = elements.getElement(CardElement);

    const { error, paymentIntent } = await stripe.confirmCardPayment(clsc, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: name,
        },
      },
    });

    if (error) {
      console.log("[error]", error);
      // Post api with error as the payload
      await axios
        .post(
          `${app_url}/api/payment/pushStripePaymentError`,
          { error: error },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((dataRes) => {
          if (
            error.type === "card_error" ||
            error.type === "validation_error"
          ) {
            // setMessage(error.message);
            toast.error(error.message);
          } else if (error.type === "invalid_request_error") {
            toast.error(error.message);
            console.log("invalid_request_error", error.message);
            navigate("/home");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            navigate("/");
          }
        });
    } else if (paymentIntent) {
      axios
        .post(`${app_url}/api/payment/partnerStripePayCompletion`, {
          paymentIntent: paymentIntent,
        })
        .then((res) => {
          console.log(res);
          toast.success(res.data.message);
          setIsLoading(false);
          navigate("/accountBalance");
        })
        .catch((err) => {
          toast.error(err.response?.data.message);
          setIsLoading(false);
          navigate("/home");
        });
      // handle successful payment here
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="border h-full flex flex-col p-4 justify-between w-full"
    >
      <div>
        <label className="text-gray-500">Cardholder Name</label>
        <input
          type="text"
          placeholder="Full name on card"
          onChange={(e) => setName(e.target.value)}
          className="border px-2 py-2 rounded w-full mt-2 mb-4"
        />
        <label className="text-gray-500">Card Information</label>
        <CardElement className="text-white mt-2 mb-4 border py-4 rounded px-4 bg-white" />
      </div>
      <div>
        <button
          disabled={isLoading || !stripe || !elements}
          type="submit"
          className={`${
            isLoading ? "cursor-not-allowed bg-blue-300" : "cursor-pointer"
          } rounded py-2 text-white bg-blue-700 w-full`}
        >
          {isLoading
            ? "..Loading"
            : `Pay ${new Number(amount).toFixed(2)} ${currency}`}
        </button>
      </div>
    </form>
  );
};

export default CheckoutForm;
