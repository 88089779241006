import React from "react";
import CustomerPdf from "../../../components/CustomerPdf";
import { useParams } from "react-router-dom";

const PDF = () => {
  const { cs } = useParams();

  return <CustomerPdf cs={cs} />;
};

export default PDF;
