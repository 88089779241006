import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useContext, lazy, Suspense } from "react";
import useResponsive from "./customHooks/useResponsive";
import { AuthContext } from "./context/authContext";
import PaymentReceiptSlug from "./services/[slug]";
import NoDataFound from "./components/NoDataFound";
import CustomModal from "./components/CustomModal";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-modal/styles.css";
import { Toaster } from "react-hot-toast";
import "slick-carousel/slick/slick.css";
import "react-loading-skeleton/dist/skeleton.css";
import SingleBulk from "./pages/eSims/[slug]/SingleBulk";
import PDF from "./pages/Customers/[slug]/PDF";
import Stp from "./services/stripe/Stp";
import Paypal from "./services/paypal/Paypal";

const Login = lazy(() => import("./pages/Auth/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const LocalPackages = lazy(() => import("./pages/Packages/LocalPackages"));
const RegionalPackages = lazy(() =>
  import("./pages/Packages/RegionalPackages")
);
const GlobalPackages = lazy(() => import("./pages/Packages/GlobalPackages"));
const MyOrders = lazy(() => import("./pages/Orders/MyOrders"));
const MyCustomers = lazy(() => import("./pages/Customers/MyCustomers"));
const AccountBalance = lazy(() => import("./pages/Balance/AccountBalance"));
const AccountSettings = lazy(() => import("./pages/Settings/AccountSettings"));
const PrivateRoutes = lazy(() => import("./privateRoute/PrivateRoutes"));
const LocalSlug = lazy(() => import("./pages/Packages/local/[slug]"));
const PaymentProceed = lazy(() =>
  import("./pages/Packages/local/PaymentProceed")
);
const RegionalSlug = lazy(() => import("./pages/Packages/regional/[slug]"));
const MyOrderSlug = lazy(() => import("./pages/Orders/[slug]"));
const Inventory = lazy(() => import("./pages/eSims/Inventory"));
const Notification = lazy(() => import("./pages/Notifications/Notification"));
const BulkAssignment = lazy(() => import("./pages/eSims/BulkAssignment"));
const Test = lazy(() => import("./pages/Test"));

function App() {
  const context = useContext(AuthContext);
  const { width } = useResponsive();

  return (
    <>
      <div
        className={
          context.sideBarOpened
            ? `${width > 950 ? "" : "empty"}`
            : context.customerModalOpen
            ? "empty"
            : ""
        }
      ></div>
      <div className="App bg-slate-100">
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{
            className: "",
            style: {
              fontSize: "16px",
              padding: "10px 20px",
            },
          }}
        />
        <BrowserRouter>
          <Suspense
            fallback={
              <div className="min-h-screen bg-primary flex items-center justify-center">
                <CustomModal />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Login />} />
              <Route element={<PrivateRoutes />}>
                <Route path="/home" element={<Dashboard />} />
                <Route path="/local" element={<LocalPackages />} />
                <Route path="/regional" element={<RegionalPackages />} />
                <Route path="/global" element={<GlobalPackages />} />
                <Route path="/myOrders" element={<MyOrders />} />
                <Route path="/eInventory" element={<Inventory />} />
                <Route path="/myOrders/:id" element={<MyOrderSlug />} />
                <Route path="/myCustomers" element={<MyCustomers />} />
                <Route path="/accountBalance" element={<AccountBalance />} />
                <Route path="/accountSettings" element={<AccountSettings />} />
                <Route path="/notifications" element={<Notification />} />
                <Route path="/local/:id" element={<LocalSlug />} />
                <Route path="/regional/:id" element={<RegionalSlug />} />
                <Route
                  path="/confirm-order/sessionId"
                  element={<PaymentProceed />}
                />
                <Route
                  path="/paymentReceipt"
                  element={<PaymentReceiptSlug />}
                />
                <Route path="/errPage" element={<NoDataFound />} />
                <Route path="/test" element={<Test />} />
                <Route path="/bulkAssignment" element={<BulkAssignment />} />
                <Route
                  path="/bulkAssignment/:id/:type"
                  element={<SingleBulk />}
                />
                <Route path="/myCustomers/:cs" element={<PDF />} />
                <Route
                  path="/stp/:pb/:clsc/:amount/:currency"
                  element={<Stp />}
                />
                <Route path="/paypalproceed/:uuid" element={<Paypal />} />
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
