import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const NoDataFound = ({err}) => {
  const location = useLocation();
  const navigate = useNavigate()
  // Access the state from the location object
  const { state } = location;


  return (
    <div className="text-center h-500 w-full flex flex-col items-center justify-center">
                    <h2 className="text-4xl font-semibold">
                        {state.message}!
                        <br/>
                <span className="text-sm text-gray-500">Please try again later</span>
                        </h2>
           <button onClick={() => navigate('/home')} className='bg-green-500 px-2 mt-5 py-1 rounded-lg'>Return to home</button>
     </div>
  )
}

export default NoDataFound